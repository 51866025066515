<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <div class="brand-logo">
        <vuexy-logo />
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            fluid
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            <span v-if="!passwordChanged">Смена пароля </span>
            <span v-else>Пароль успешно изменен </span>
          </b-card-title>
          <b-card-text class="mb-2">
            <span v-if="!checkExecuted">Введите номер для получения кода подтверждения</span>
            <span v-else-if="!passwordChanged">Введите код – <b class="text-primary">последние 4 цифры номера полученного звонка</b> – и укажите новый пароль. Ваш пароль должен содержать <b class="text-primary">не менее 6</b> символов</span>
            <span v-else>Вы можете вернуться к авторизации и войти с новым паролем</span>
          </b-card-text>

          <!-- form -->

          <template v-if="!checkExecuted">
            <b-form
              class="auth-reset-password-form mt-2"
              @submit.prevent="getConfirmationCode"
            >
              <b-form-group label="Ваш телефон">
                <b-input-group>
                  <VuePhoneNumberInput
                    v-model="phone"
                    v-validate="'phone-is-correct'"
                    data-vv-validate-on="blur"
                    class="w-100"
                    color="#7367f0"
                    :dark="$store.state.appConfig.layout.skin === 'dark'"
                    valid-color="#28A745"
                    error-color="#DC3545"
                    no-example
                    :translations="{
                      countrySelectorLabel: 'Страна',
                      countrySelectorError: 'Ошибка',
                      phoneNumberLabel: 'введите номер телефона',
                      example: 'Пример :'
                    }"
                    :only-countries="['RU', 'BY', 'KZ', 'AM']"
                    name="phone"
                    @update="phoneUpdated"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors.first('phone') }}</small>
              </b-form-group>

              <phone-check
                full-width
                :code-approved="false"
                :code-wrong="false"
                :code-approving="false"
                :code-requesting="codeRequesting"
                :phone-is-taken="false"
                :check-executed="checkExecuted"
                @codeRequested="getConfirmationCode"
                @checkCode="checkCode"
              />
            </b-form>
          </template>

          <template v-else-if="!passwordChanged">
            <b-form
              class="auth-reset-password-form mt-2"
              @submit.prevent="resetPassword"
            >
              <b-form-group label="4 последние цифры номера">
                <b-form-input
                  v-model="code"
                  v-validate="'required|min:4|max:4'"
                  maxlength="4"
                  minlength="4"
                  name="code"
                  :state="errors.first('code') || (passwordChangeErrors && passwordChangeErrors.code) ? false:null"
                  placeholder="Введите код"
                  autocomplete="off"
                  type="text"
                  data-vv-validate-on="blur"
                />
                <small class="text-danger">{{ errors.first('code') }}</small>
                <small
                  v-if="passwordChangeErrors && passwordChangeErrors.code"
                  class="text-danger"
                >
                  {{ passwordChangeErrors.code }}
                </small>
              </b-form-group>

              <div class="mb-1 d-flex align-items-center">
                <b-button
                  class="mr-50 mt-0"
                  variant="outline-primary"
                  block
                  type="button"
                  :disabled="timeRemaining > 0"
                  @click="sendCodeRequest"
                >
                  <feather-icon
                    class="mr-50"
                    icon="PhoneOutgoingIcon"
                  />
                  Повторить звонок <span v-if="timeRemaining > 0">- {{ timeRemaining }}</span>
                </b-button>
                <b-button
                  class="ml-50 mt-0"
                  variant="outline-primary"
                  block
                  type="button"
                  :disabled="timeRemaining > 0"
                  @click="sendSms"
                >
                  <feather-icon
                    class="mr-50"
                    icon="PhoneOutgoingIcon"
                  />
                  Отправить смс <span v-if="timeRemaining > 0">- {{ timeRemaining }}</span>
                </b-button>
              </div>

              <!-- password -->
              <password-check
                ref="password"
                column
                no-header
              />

              <!-- submit button -->
              <b-button
                class="mt-2"
                block
                type="submit"
                variant="primary"
                :disabled="passwordChanging"
              >
                <b-spinner
                  v-if="passwordChanging"
                  small
                  class="mr-50"
                />
                <span v-if="!passwordChanging">Сохранить новый пароль</span>
                <span v-else>Изменение пароля</span>
              </b-button>
            </b-form>
          </template>

          <template v-else>
            <div class="text-center d-flex flex-column align-items-center">
              <b-button
                variant="outline-primary"
                class="mt-4"
                :to="{name:'auth.login'}"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  class="mr-50"
                />
                Авторизация
              </b-button>
            </div>
          </template>

          <p
            v-if="!passwordChanged"
            class="text-center mt-2"
          >
            <b-link :to="{name:'auth.login'}">
              <feather-icon icon="ChevronLeftIcon" /> Назад к авторизации
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BLink, BButton, BImg, BFormInput, BSpinner,
} from 'bootstrap-vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import store from '../../store'
import PhoneCheck from './register/PhoneCheck.vue'
import PasswordCheck from './register/PasswordCheck.vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  components: {
    PasswordCheck,
    PhoneCheck,
    VuexyLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    VuePhoneNumberInput,
    BFormInput,
    BSpinner,
  },
  data() {
    return {
      phone: '',
      code: null,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',

      sideImg: require('../../assets/images/pages/forgot-password-v2.svg'),

      codeRequesting: false,
      checkExecuted: false,
      phonePayload: null,

      passwordChanging: false,
      passwordChanged: false,
      passwordChangeErrors: null,

      timeRemaining: 60,
      callInterval: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('../../assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    dict() {
      return {
        custom: {
          phone: {
            required: 'Необходимо указать телефон',
            'phone-is-correct': 'Телефон введен некорректно',
          },
          code: {
            required: 'Необходимо ввести код',
            min: 'Код должен содержать 4 символа',
            max: 'Код должен содержать 4 символа',
          },
        },
      }
    },
    phoneIsValid() {
      if (!this.phonePayload) return false
      return this.phonePayload.isValid
    },
  },
  watch: {
    checkExecuted() {
      if (this.checkExecuted) {
        this.timeRemaining = 60
        this.callInterval = setInterval(() => {
          if (this.timeRemaining === 0) {
            clearInterval(this.callInterval)
            return
          }
          this.timeRemaining -= 1
        }, 1000)
      }
    },
  },
  mounted() {
    this.$validator.extend('phone-is-correct', () => ({
      valid: this.phoneIsValid,
      data: {
        required: true,
      },
    }),
    {
      computesRequired: true,
    })
    this.$validator.localize('ru', this.dict)
  },
  methods: {
    phoneUpdated(payload) {
      this.phonePayload = payload
    },
    async resetPassword() {
      await this.$validator.validate().then(async valid => {
        if (valid) {
          const {
            phonePayload, code,
          } = this

          const data = await this.$refs.password.submit()
          if (!data) return
          this.passwordChanging = true

          const { password, password_repeat } = data

          const phone = phonePayload.formattedNumber.replace(/\D/g, '')

          await this.$http.post('/auth/password-recovery-check-code', {
            phone, code, password, password_repeat,
          })
            .then(() => {
              this.passwordChanged = true
              this.passwordChanging = false
            }).catch(err => {
              this.passwordChanging = false
              this.passwordChangeErrors = err
            })
        }
      })
    },
    checkCode(code) {
      this.code = code
    },
    async sendCodeRequest() {
      this.checkExecuted = false
      this.codeRequesting = true
      const phone = this.phonePayload.formattedNumber.replace(/\D/g, '')
      await this.$http.post('/auth/password-recovery-send-code', { phone })
        .then(() => {
          this.checkExecuted = true
          this.codeRequesting = false
        }).catch(() => {
          this.codeRequesting = false
        })
    },
    async getConfirmationCode() {
      await this.$validator.validate().then(async valid => {
        if (valid) await this.sendCodeRequest()
      })
    },
    async sendSms() {
      this.checkExecuted = false
      this.codeRequesting = true
      const phone = this.phonePayload.formattedNumber.replace(/\D/g, '')
      await this.$http.post('/auth/password-recovery-send-sms', { phone })
        .then(() => {
          this.checkExecuted = true
          this.codeRequesting = false
        }).catch(() => {
          this.codeRequesting = false
        })
    },
    async getConfirmationSmsCode() {
      await this.$validator.validate().then(async valid => {
        if (valid) this.sendSms()
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
